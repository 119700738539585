<template>
  <section class="graph__survey">
    <header>
      <div v-html="humanDate"/>
    </header>

    <div>
      <calendar-caption :display.sync="caption.display"/>

      <canvas-day-calendar :today="today"
                           :step-time="5"
                           :read-only="isReadSurvey"
                           @update-graph="onUpdateGraph"
      />

      <aside>
        <v-btn fab
               @click="onAddItem('addSlider', {type: 'sleep', today})"
               :disabled="hasSleepSlider || isReadSurvey"
        >
          <calendar-svg-sleep plus :size="40" :color="svgColor"/>
        </v-btn>
        <v-btn fab
               @click="onAddItem('addSlider', {type: 'nap', today})"
               :disabled="isReadSurvey"
        >
          <calendar-svg-nap plus :size="40"/>
        </v-btn>
        <v-btn fab
               @click="onAddItem('addSlider', {type: 'lumino', today})"
               :disabled="(!userLuminoStarted && !surveyLuminoStarted) || isReadSurvey"
        >
          <calendar-svg-bulb plus :size="40" :color="svgColor"/>
        </v-btn>
        <v-btn fab @click="onAddItem('addCapsule', today)" :disabled="isReadSurvey">
          <calendar-svg-capsule plus :size="25"/>
        </v-btn>
        <v-btn color="blue" fab @click="onDisplayCaption">
          <v-icon size="35">mdi-information-outline</v-icon>
        </v-btn>
      </aside>
    </div>

    <footer>
      <day-information-container @update-value="onUpdate" :read-only="isReadSurvey"/>
    </footer>
  </section>
</template>

<script>
import CanvasDayCalendar from '@/components/Calendar/Calendar'
import CalendarSvgSleep from '@/components/Calendar/Icon/Sleep'
import CalendarSvgNap from '@/components/Calendar/Icon/Nap'
import CalendarSvgBulb from '@/components/Calendar/Icon/Bulb'
import CalendarSvgCapsule from '@/components/Calendar/Icon/Capsule'
import { mapActions, mapGetters } from 'vuex'
import DayInformationContainer from '@/components/Calendar/DayInformationContainer'
import questionMixins from '@/components/Survey/Reader/questionMixins'
import CalendarCaption from '@/components/Calendar/CalendarCaption'
import { dateEqual, dateInferior } from '@/store/helpers/survey/date'
import { getHumanDate } from '@/store/modules/Agenda'

export default {
  name: 'SurveyQuestionGraph',

  components: {
    CalendarCaption,
    DayInformationContainer,
    CalendarSvgCapsule,
    CalendarSvgBulb,
    CalendarSvgNap,
    CalendarSvgSleep,
    CanvasDayCalendar,
  },

  mixins: [questionMixins],

  props: {
    answers: {
      required: true,
    },
  },

  data: () => ({
    capsules: {
      list: [],
      i: 0,
    },
    sliders: {
      list: [],
      i: 0,
    },
    caption: {
      display: false,
    },
  }),

  computed: {
    ...mapGetters([
      'hasSleepSlider',
      'userLuminoStarted',
      'user',
    ]),
    humanDate () {
      const { year: yearToday, month: monthToday, date: dateToday } = getHumanDate(1, this.today)
      const { year: yearYesterday, month: monthYesterday, date: dateYesterday } = getHumanDate(-1, this.today)
      return `<div>${dateYesterday}/${monthYesterday}/${yearYesterday}</div><div>${dateToday}/${monthToday}/${yearToday}</div>`
    },
    today () {
      if (this.user) {
        const today = new Date(this.user.inclusionDate)
        today.setDate(today.getDate() + parseInt(this.$route.params.day))
        return today
      }
      return null
    },
    surveyLuminoStarted () {
      const q7Result = this.answers['Q7']
      if (q7Result === 0) return true

      const q71Result = new Date(this.answers['Q7.1'])
      const today = new Date()
      return q7Result === 1 && (dateEqual(q71Result, today) || dateInferior(q71Result, today))
    },
    svgColor () {
      return this.hasSleepSlider ? '#6c6c6c' : '#000000'
    },
  },

  methods: {
    ...mapActions([
      'addCapsule',
      'addSlider',
    ]),
    onAddItem (functionName, item = null) {
      this[functionName](item)
      this.onUpdate({ value: null })
    },
    onDisplayCaption () {
      this.caption.display = true
    },
    onUpdateGraph () {
      if (!this.isReadSurvey) {
        this.onUpdate({ value: null })
      }
    },
    onUpdate (value) {
      this.$emit(
          'update-value',
          {
            ...value,
            isGraph: true,
          },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/style/style";

.graph__survey {
  header::v-deep {
    display: grid;
    grid-template-columns: 1fr;
    font-size: 16px;

    @media all and (min-width: $m) {
      font-size: 35px;
      grid-template-columns: 1040px 1fr;
    }

    > div:first-of-type {
      flex-direction: row;
      display: flex;
      justify-content: space-around;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @media all and (min-width: $m) {
      flex-direction: row;
    }

    aside {
      button {
        margin-right: 10px;
        margin-bottom: 10px;

        @media all and (min-width: $m) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
