<template>
  <v-dialog
      :value="display"
      width="90vw"
      content-class="caption__calendar"
      @keydown.esc="onHideCaption"
      @click:outside="onHideCaption"
  >
    <v-card width="90vw">
      <v-card-title>{{ $t('survey.graph.caption.title') }}</v-card-title>

      <v-card-text>{{ $t('') }}
        <h2>{{ $t('survey.graph.caption.content.t1') }}</h2>

        <p>{{ $t('survey.graph.caption.content.p1') }}</p>

        <ul>
          <li>
            <calendar-svg-sleep :size="30"/>
            {{ $t('survey.graph.caption.content.l1_1') }}
          </li>
          <li>
            <calendar-svg-nap :size="30"/>
            {{ $t('survey.graph.caption.content.l1_2') }}
          </li>
          <li>
            <calendar-svg-bulb :size="30"/>
            {{ $t('survey.graph.caption.content.l1_3') }}
          </li>
          <li>
            <calendar-svg-capsule :size="16"/>
            {{ $t('survey.graph.caption.content.l1_4') }}
          </li>
        </ul>

        <p class="disabled-container">
          <img :src="require('../../assets/img/disabled_button.png')" alt="Exemple de bouton désactivé"/>
          {{ $t('survey.graph.caption.content.p2') }}
        </p>

        <h2>{{ $t('survey.graph.caption.content.t2') }}</h2>

        <div class="example_pictures">
          <img
              :src="require('@/assets/img/medicine.png')"
              alt="Impression écran montrant le curseur modifiable pour la prise médicamenteuse"
          />
          <img
              :src="require('@/assets/img/slider.png')"
              alt="Impression écran montrant une période de luminothérapie"
          />
        </div>
        <p>{{ $t('survey.graph.caption.content.p3') }}</p>

        <div class="example_pictures">
          <img
              :src="require('@/assets/img/medicine_tag.png')"
              alt="vignette pour la prise médicamenteuse"
          />
          <img
              :src="require('@/assets/img/slider_tag.png')"
              alt="vignette pour la luminothérapie"
          />
        </div>
        <p>{{ $t('survey.graph.caption.content.p4') }}</p>

        <h2>{{ $t('survey.graph.caption.content.t3') }}</h2>

        <p>{{ $t('survey.graph.caption.content.p5') }}</p>
        <div class="example_pictures img_center">
          <img
              :src="require('@/assets/img/sleep.png')"
              alt="Vignette pour une période de sommeil"
          />
        </div>

        <p>{{ $t('survey.graph.caption.content.p6') }}</p>

        <ul>
          <li>
            <calendar-svg-cursor-arrow-start class="no_width"/>
            {{ $t('survey.graph.caption.content.l2_1') }}
          </li>
          <li>
            <calendar-svg-cursor-eye-closed class="no_width"/>
            {{ $t('survey.graph.caption.content.l2_2') }}
          </li>
          <li>
            <calendar-svg-cursor-eye-opened class="no_width"/>
            {{ $t('survey.graph.caption.content.l2_3') }}
          </li>
          <li>
            <calendar-svg-cursor-arrow-end class="no_width"/>
            {{ $t('survey.graph.caption.content.l2_4') }}
          </li>
        </ul>

        <p v-html="$t('survey.graph.caption.content.p7')"/>

        <div class="example_pictures img_center">
          <img
              :src="require('@/assets/img/sup_awake.png')"
              alt="Période de réveil supplémentaire"
          />
        </div>

        <h2>{{ $t('survey.graph.caption.content.t4') }}</h2>

        <p>{{ $t('survey.graph.caption.content.p8') }}</p>

        <p>{{ $t('survey.graph.caption.content.p9') }}</p>
      </v-card-text>

      <v-card-actions>
        <v-btn @click="onHideCaption">{{ $t('survey.graph.caption.button.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CalendarSvgSleep from '@/components/Calendar/Icon/Sleep'
import CalendarSvgNap from '@/components/Calendar/Icon/Nap'
import CalendarSvgBulb from '@/components/Calendar/Icon/Bulb'
import CalendarSvgCapsule from '@/components/Calendar/Icon/Capsule'
import CalendarSvgCursorArrowStart from '@/components/Calendar/Icon/CursorArrowStart'
import CalendarSvgCursorEyeClosed from '@/components/Calendar/Icon/CursorEyeClosed'
import CalendarSvgCursorEyeOpened from '@/components/Calendar/Icon/CursorEyeOpened'
import CalendarSvgCursorArrowEnd from '@/components/Calendar/Icon/CursorArrowEnd'

export default {
  name: 'CalendarCaption',

  components: {
    CalendarSvgCursorArrowEnd,
    CalendarSvgCursorEyeOpened,
    CalendarSvgCursorEyeClosed,
    CalendarSvgCursorArrowStart,
    CalendarSvgCapsule,
    CalendarSvgBulb,
    CalendarSvgNap,
    CalendarSvgSleep,
  },

  props: {
    display: {
      required: true,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onHideCaption () {
      this.$emit('update:display', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.caption__calendar {
  .v-card__text {
    h2 {
      font-size: 25px;
      margin-bottom: 15px;

      &:not(:first-of-type) {
        margin-top: 35px;
      }
    }

    .disabled-container {
      display: flex;
      align-items: center;
    }

    .example_pictures {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.img_center {
        justify-content: center;
      }

      img {
        max-width: 100%;

        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }

    p,
    ul {
      font-size: 20px;
    }

    p {
      img {
        float: left;
        margin-right: 15px;
      }
    }

    ul {
      li {
        &::before {
          content: "•";
          font-size: 20px;
          font-family: sans-serif;
          margin-right: 15px;
        }

        display: flex;
        align-items: center;
        margin-bottom: 10px;

        svg {
          margin-right: 20px;

          &.no_width {
            width: 20px;
          }
        }
      }
    }
  }
}
</style>
